import React from "react";
import Loadable from "react-loadable";
import { Route, Routes, Navigate } from "react-router-dom";
// import "./App.css";
import AppLayout from "./components/AppLayout";
// import Listing from './components/Listing';
import { CssBaseline } from "@material-ui/core";
import Homepage from "./components/Homepage";
import Auth from "./authenticate";
import VerifyOTP from "./components/DoctorLogin/VerifyOTP";
import Home from "./components/Home";
import ControlPanelLayout from "./components/ControlPanelLayout";
import MyProfile from "./components/DoctorProfile/MyProfile";
import Doctors from "./components/Doctors";
import ComingSoon from "./components/Commons/ComingSoon";
import Themes from "./components/Appearance/Themes";
import Design from "./components/Appearance/Design";
import Gallery from "./components/Appearance/Gallery";
import AboutUs from "./components/Appearance/AboutUs";
import ContactUs from "./components/Appearance/ContactUs";
import Faqs from "./components/Appearance/Faqs";
import Blogs from "./components/Blogs";
import AddBlog from "./components/Blogs/AddBlog";
import Services from "./components/Appearance/Services";
import EditBlog from "./components/Blogs/EditBlog";
import EditSchedule from "./components/Doctors/EditSchedule.js";
import Appointments from "./components/Appointments/index.js";

const isLoggedin = Auth.isAuthenticated();

function LoadingComponent(props) {
  if (props.error) {
    // When the loader has errored
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <div>Loading...</div>;
  } else {
    // When the loader has just started
    return null;
  }
}

const Login = Loadable({
  loader: () => import("./components/Login"),
  loading: LoadingComponent,
  delay: 500,
});

const Signup = Loadable({
  loader: () => import("./components/Login/Signup.js"),
  loading: LoadingComponent,
  delay: 500,
});


function HardRedirect({ to }) {
  window.location = to.pathname || "/";
  return null;
}

function PrivateRoute({ element: Component, authed, ...rest }) {
  if (typeof window !== "undefined") {
    return (
      <Route
        {...rest}
        render={(props) => {
          let redirectTo = "/login";
          // if (props.location.pathname == "/" && props.location.search == "")
          //   redirectTo = "/home";
          console.log("PrivateRoute redirectTo ", props.location, redirectTo);
          return authed === true ? (
            <Component {...props} />
          ) : (
            <Navigate
              to={{
                pathname: redirectTo,
                state: { from: props.location },
              }}
              replace
            />
          );
        }}
      />
    );
  } else {
    return "";
  }
}

const RoutesComponent = () => (
  <div>
    <CssBaseline />
    <Routes>
      <Route
        path="/"
        element={
          isLoggedin ? (
            <Navigate to="/home" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/login"
        element={
          <AppLayout showNavbar={false} showFooter={false}>
            <Login />
          </AppLayout>
        }
      />
      <Route
        path="/sign-up"
        element={
          <AppLayout showNavbar={false} showFooter={false}>
            <Signup />
          </AppLayout>
        }
      />
      <Route
        path="/login/:inviteeToken"
        element={
          <AppLayout showNavbar={false} showFooter={false}>
            <Login />
          </AppLayout>
        }
      />
      <Route
        path="/verify-otp"
        element={
          <AppLayout>
            <VerifyOTP />
          </AppLayout>
        }
      />
      <Route
        path="/home"
        element={
          <ControlPanelLayout>
            <Home />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/my-profile"
        element={
          <ControlPanelLayout>
            <MyProfile />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/doctors"
        element={
          <ControlPanelLayout>
            <Doctors />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/doctors/edit/:id/:doctorName"
        element={
          <ControlPanelLayout>
            <EditSchedule />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/coming-soon"
        element={
          <ControlPanelLayout>
            <ComingSoon />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appearance/themes"
        element={
          <ControlPanelLayout>
            <Themes />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appearance/design"
        element={
          <ControlPanelLayout>
            <Design />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appearance/gallery"
        element={
          <ControlPanelLayout>
            <Gallery />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appearance/about-us"
        element={
          <ControlPanelLayout>
            <AboutUs />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appearance/contact-us"
        element={
          <ControlPanelLayout>
            <ContactUs />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appearance/faqs"
        element={
          <ControlPanelLayout>
            <Faqs />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appearance/services"
        element={
          <ControlPanelLayout>
            <Services />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/blogs"
        element={
          <ControlPanelLayout>
            <Blogs />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/blogs/add"
        element={
          <ControlPanelLayout>
            <AddBlog />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/blogs/edit/:blogCode"
        element={
          <ControlPanelLayout>
            <EditBlog />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/:id/coming-soon"
        element={
          <ControlPanelLayout>
            <ComingSoon />
          </ControlPanelLayout>
        }
      />
      <Route
        path="/appointments"
        element={
          <ControlPanelLayout>
            <Appointments />
          </ControlPanelLayout>
        }
      />
    </Routes>
  </div>
);

export default React.memo(RoutesComponent);
